<template>
  <div id="app">
    <main class="main_container_wrapper">
		<section class="main_container">
			<div class="top_text_line">
				<router-link to="/" class="top_text_line_wrapper">
					<img v-if="logo" :src="logo" alt="" class="logo">
				<!-- <h2 class="top_line_product_text">Заголовок продукта или логотип</h2> -->
				</router-link>
			</div>
			<div class="dynamic_container">
          		<router-view
					:maxAmount="maxAmount"
					:stopper="stopper"
					:findParentByClassName="findParentByClassName"
					:addGAEvent="addGAEvent"
					:checkEmail="checkEmail"
					:showPopup="showPopup"
					:showSomeBadPopup="showSomeBadPopup"
              		:showBadPopupText="showBadPopupText"
			      	:footerText="footerText"
			      	:footerRules="footerRules"
					:isCameraAtcive="isCameraAtcive"
					@setNewCameraState="setNewCameraState"
          		/>
        	</div>
      	</section>
    </main>
    <section class="popups_section">
      <div id="order_accepted_popup" class="popup_item">
        <p class="popup_text_mini">Спасибо, ваш заказ принят и уже готовится, ожидайте получения</p>
        <a href="index.html" class="classic_button color_button"><span>Вернуться к покупкам</span></a>
      </div>
      <div id="complete_order_popup" class="popup_item success_popup">
        <p class="popup_text">Заказ готов</p>
        <a href="index.html" class="classic_button color_button"><span>Вернуться к покупкам</span></a>
      </div>
      <div id="base_popup" class="popup_item">
        <p class="popup_text"></p>
        <a href="#" @click="closePopup($event)" class="classic_button color_button"><span>Понятно</span></a>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'App',
    data() {
      return {
        maxAmount: 99,
        isCameraAtcive: false,
		logo: '',
		footerText: '',
		footerRules: ''
      }
    },
    methods: {
      setHeight() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      },
      showCompleteOrderPopup() {
        this.showPopup('#complete_order_popup', null, 'success_popup')
      },
      showNotPaidOrderPopup() {
        this.showPopup('#base_popup', 'Заказ не оплачен', 'bad_popup')
        this.addGAEvent('Barman', 'Barman', 'Order', 'Not Paid')
      },
      showNotValidCodePopup() {
        this.showPopup('#base_popup', 'Код невалиден', 'bad_popup')
        this.addGAEvent('Barman', 'Barman', 'Code', 'Invalid')
      },
      showOrderAcceptedPopup() {
        this.showPopup('#order_accepted_popup', null, null)
        this.addGAEvent('Barman', 'Barman', 'Order', 'Making')
      },
      showSomeBadPopup() {
        this.showPopup('#base_popup', 'Что-то пошло не так<br> попробуйте еще раз', 'bad_popup')
        this.addGAEvent('Barman', 'Barman', 'Order', 'Wrong')
      },
      showBadPopupText(text) {
        this.showPopup('#base_popup', text, 'bad_popup')
      },
      showPopup(popupSelector, popupText, popupClass) {
        let popupsContainer = document.querySelector('.popups_section')

        this.hideAllPopups();

        let newActivePopup = popupsContainer.querySelector(popupSelector)

        if (popupText) {
          let popupTextContainer = newActivePopup.querySelector('.popup_text')
          popupTextContainer.innerHTML = popupText;
        }

        newActivePopup.classList.remove('success_popup')
        newActivePopup.classList.remove('bad_popup')

        if (popupClass) {
          newActivePopup.classList.add(popupClass)
        }

        if (newActivePopup) {
          popupsContainer.classList.add('active_popups_section')
          newActivePopup.classList.add('active_popup_item')
        }
      },
      hideAllPopups() {
        let popupsContainer = document.querySelector('.popups_section');
        let popups = popupsContainer.querySelectorAll('.popup_item');

        for (let i = 0; i < popups.length; i++) {
          popups[i].classList.remove('active_popup_item');
        }

        popupsContainer.classList.remove('active_popups_section');
      },
      closePopup(event) {
        this.stopper(event);

        let activePopup = this.findParentByClassName('popup_item', event.target);
        let popupsContainer = this.findParentByClassName('popups_section', activePopup);

        activePopup.classList.remove('active_popup_item');
        popupsContainer.classList.remove('active_popups_section');

        this.setNewCameraState(false)
      },
	  setNewCameraState(state) {
		  this.isCameraAtcive = state
	  },
      stopper(event) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
      },
      findParentByClassName(parentClassName, elem) {
        while(elem !== document.body) {

          if(elem.classList.contains(parentClassName)) {
            return elem;
          }

          elem = elem.parentNode;
        }
      },
      addGAEvent(eventname, eventCategory, eventAction, eventLabel) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': eventname,
          'eventCategory': eventCategory,
          'eventAction': eventAction,
          'eventLabel': eventLabel
        });
      },
      checkEmail(value) {
        let valid = true;

        if (value.indexOf('@') == -1) {
          valid = false;
        } else {

          let parts = value.split('@');
          let domain = parts[1];

          if (domain.indexOf('.') == -1) {
            valid = false;
          } else {
            let domainParts = domain.split('.');
            let ext = domainParts[1];

            if (ext.length > 8 || ext.length < 2) {
              valid = false;
            }
          }
        }

        return valid;
      },
      checkUtm() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let status = urlParams.get('success')

        if (status) {
          let orderToken = urlParams.get('i')

          if (orderToken) {
            this.$router.push('/order/' + orderToken);
          }
        }

      }
    },
    mounted() {
      	this.setHeight()
      	window.addEventListener('resize', this.setHeight)

      	this.checkUtm()

		let headers = {
        	'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
      	}

      	axios.get(
        	'https://platillo.ru/api/v1/project/settings',
        	{headers: headers},
    	).then((value) => {
        	console.log('Success answer /project/settings')
        	console.dir(value)

			this.logo = value.data.data.logo
			this.footerText = value.data.data['footer_info']
			this.footerRules = value.data.data['rules']

      	}).catch((value) => {
  		})
    }
}
</script>

<style>
  :root {
    --color-1: #6D39FF;
    --color-2: #00B6FF;
  }
  #app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    /* color: #2c3e50; */
  }

  /*----------common start----------*/

  html,
  body {
    width: 100%;
    height: auto;
  }
  * {
    margin: 0;
    padding: 0;
  }
  html {
    font-family: 'Montserrat', sans-serif;
    font-size: 1vh;
    font-size: calc(var(--vh, 1vh) * 1);
    color: #000000;
  }
  body.hidden {
    overflow: hidden;
  }
  .desktop {
    display: none !important;
  }
  .classic_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
      height: 6rem;
    font-size: 2.3rem;
    line-height: 1.1em;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    border: none;
    outline: none;
    text-align: center;
    position: relative;
  }
  .classic_button span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }
  .classic_button.white_button span {
    background-color: #FFFFFF;
    color: #000000;
  }
  .classic_button.color_button span {
    background-color: var(--color-1);
    color: #FFFFFF;
  }
  .light_text {
    font-weight: 400;
  }
  .main_container_wrapper {
    display: block;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #DB2433;
  }
  .main_container {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #FFFEFE;
    position: relative;
    padding-top: 1rem;
    box-sizing: border-box;
  }
  .top_text_line {
    display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
    width: 100%;
    height: 8rem;
    padding: 0 3vw;
    box-sizing: border-box;
  }
  .top_text_line_wrapper {
	display: block;
	width: auto;
	height: 100%;
  }
  .top_text_line_wrapper img {
	  display: block;
	  width: 100%;
	  height: 100%;
	  object-fit: contain;
  }
  .top_line_product_text {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 2.6rem;
    line-height: 1em;
    /*text-transform: uppercase;*/
    color: var(--color-1);
  }
  .dynamic_container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }
  .dynamic_item {
    display: block;
    width: 100%;
    height: 90rem;
    opacity: 1;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 1rem;
    overflow: hidden;
  }
  .exit_button_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: auto;
  }
  .exit_button {
    display: block;
    width: auto;
    height: auto;
    text-align: center;
    font-size: 1.7rem;
    line-height: 1em;
    font-weight: 500;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    filter: 
      drop-shadow(0px 4px 4px rgba(50, 50, 71, 0.08)) 
      drop-shadow(0px 4px 8px rgba(50, 50, 71, 0.06));
    border-radius: .5rem;
    background-color: #FAFAFA;
    padding: 1.2em 3em;
  }

  /*----------common end----------*/

  /*----------popups start----------*/

  .popups_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -999;
    opacity: 0;
    transition: opacity .4s ease;
    background-color: rgba(0, 0, 0, .6);
  }
  .popups_section.active_popups_section {
    opacity: 1;
    z-index: 999;
  }
  .popup_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 86vw;
    height: auto;
    background-color: #FFFFFF;
    border-radius: .5rem;
    filter: 
      drop-shadow(0px 16px 16px rgba(50, 50, 71, 0.08)),
      drop-shadow(0px 24px 32px rgba(50, 50, 71, 0.08));
    position: absolute;
    opacity: 0;
    z-index: -999;
    transition: transform .4s ease, opacity .4s ease;
    transform: translateY(-2rem);
    opacity: 0;
    padding: 2rem 3rem 6rem;
    box-sizing: border-box;
  }
  #order_accepted_popup {
    padding-top: 8rem;
  }
  .popup_item.active_popup_item {
    z-index: 999;
    opacity: 1;
    transform: none;
  }
  .popup_item.success_popup:before,
  .popup_item.bad_popup:before {
    content: '';
    display: block;
    width: 6rem;
    height: 6rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 50%;
    filter: drop-shadow(0 .4rem 1.5rem rgba(50, 50, 71, 0.15));
  }
  .popup_item.success_popup:before {
    background-image: url(./assets/img/icon_ok.png);
  }
  .popup_item.bad_popup:before {
    background-image: url(./assets/img/icon_bad_red.png);
  }
  .popup_text {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 3rem;
    line-height: 1.1em;
    font-weight: 400;
    margin-top: 2rem;
  }
  .popup_text_mini {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.2em;
    font-weight: 600;
  }
  .popup_item .classic_button {
    margin-top: 4rem;
  }

  /*----------popups end----------*/

  /*----------login start----------*/

  .login_container {
    display: block;
    width: 100%;
    height: auto;
    padding: 0 6vw;
    box-sizing: border-box;
    padding-top: 15rem;
  }
  .hello_barman_text {
    display: block;
    width: 100%;
    height: auto;
    text-align: left;
    font-size: 4rem;
    line-height: 1.2em;
    font-weight: 900;
    text-transform: uppercase;
  }
  .form_label {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1rem;
    position: relative;
  }
  .login_container .form_label {
    margin-top: 4rem;
  }
  .form_input {
    display: block;
    width: 100%;
    height: auto;
    border: none;
    outline: none;
    font-size: 1.8rem;
    line-height: 1em;
    padding: 1.2em 1.4em;
    box-sizing: border-box;
    background-color: #FAFAFA;
    color: #000000;
    margin-top: .5rem;
    border-radius: .5rem;
  }
  .form_input::placeholder {
      color: #AFAFAF;
      font-family: 'Montserrat';
  }
  .label_error {
    display: block;
    width: auto;
    height: 1.1em;
    font-size: 1.5rem;
    line-height: 1em;
    margin-top: .5em;
    font-weight: 500;
    color: #DB2433;
  }
  #enter_barman_button {
    width: 100%;
    margin-top: 2rem;
  }

  /*----------login end----------*/

  /* desktop */

  @media screen and (min-aspect-ratio: 4/3) {

    .main_container_wrapper {
      overflow: hidden;
    }
    .popup_item {
      width: auto;
      max-width: 40vw;
    }
  }

  /* iPhone 4/4S */

  @media 
    only screen and (min-device-width: 320px) 
    and (max-device-width: 480px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (device-aspect-ratio: 2/3) {

    .classic_button {
        width: 35rem;
      height: 6.5rem;
      font-size: 2.8rem;
    }
    .dynamic_item {
    	height: 40rem;
    }
    .login_container {
      height: auto;
    }
}

</style>